<template>
  <b-modal
    ref="search-modal"
    no-close-on-backdrop
    cancel-variant="outline-secondary"
    centered
    size="xl"
    hide-footer
    :title="$t('addItems')"
  >

    <b-row v-if="storeId">
      <b-col md="4">
        <g-field
          id="code"
          v-silent-search
          :value.sync="filter.code"
          label-text="code"
          name="code"
          @keydown.enter="
            (v) => {
              refreshItems();
            }"
        />
      </b-col>

      <b-col md="4">
        <g-field
          v-silent-search
          :value.sync="filter.arabicNamePart"
          label-text="arabicName"
          name="arabicName"
          @keydown.enter="
            (v) => {
              refreshItems();
            }"
        />
      </b-col>
      <b-col md="4">
        <g-field
          v-silent-search
          :value.sync="filter.englishNamePart"
          label-text="englishName"
          name="arabicName"
          @keydown.enter="
            (v) => {
              refreshItems();
            }"
        />
      </b-col>
      <b-col
        cols="12"
        class="pb-2"
      >
        <b-form-checkbox
          v-model="showSelected"
          :value="true"
          class="custom-control-primary"
          @change="(v) => { totalRows = 0 }"
        >
          {{ $t('showItems') }}
        </b-form-checkbox>
      </b-col>
      <b-col
        cols="12"
        class="medium-window"
      >
        <b-table
          ref="items-table"
          show-empty
          striped
          hover
          small
          responsive
          sticky-column
          class="position-relative"
          primary-key="id"
          :items="showSelected ? items : itemsSearchProvider"
          :fields="tableColumns"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          :sort-direction="sortDirection"
          :busy="isTableBusy"
          :empty-text="$t('noMatchingRecordsFound')"
          :tbody-tr-class="(item) => {
            if (item && item.quantity > item.convertedBalance) return 'table-danger';
            return item && item.quantity ? 'table-primary' : ''
          }"
        >

          <template #head(actions)>
            <span />
          </template>
          <template #table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :thStyle="field.thStyle"
            >
          </template>
          <template #cell(unit)="{ item }">
            <g-field
              field="select"
              item-id="unitId"
              placeholder="unit"
              :value.sync="item.unitId"
              class="select-size-sm"
              :options="item.units"
              :label="isRight ? 'unitArabicName' : 'unitEnglishName'"
              :dir="isRight ? 'rtl' : 'ltr'"
            />
          </template>
          <template #cell(actions)="{ item }">
            <div class="quantity-input text-nowrap">
              <b-input-group size="sm">
                <b-input-group-prepend>
                  <b-button
                    variant="outline-secondary"
                    :disabled="item.components.length > 0"
                    @click="
                      (val) => {
                        adaptQuantity(item, 1);
                      }
                    "
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                </b-input-group-prepend>
                <b-form-input
                  v-model.number="item.quantity"
                  size="sm"
                  :disabled="item.components.length > 0"
                  type="number"
                  :placeholder="$t('quantity')"
                  @change="
                    (val) => {
                      adaptQuantity(item, 0);
                    }
                  "
                />
                <b-input-group-append>
                  <b-button
                    variant="outline-danger"
                    :disabled="item.components.length > 0"
                    @click="
                      (val) => {
                        adaptQuantity(item, -1);
                      }
                    "
                  >
                    <feather-icon icon="MinusIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

          <!-- page length -->
          <b-form-group
            :label="$t('entries')"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="perPageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows || items.length"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
        <!-- <g-table>
          :createButton="{ visiable: false }"
          :searchInput="{ visiable: false }"
        >
        </g-table> -->
      </b-col>
      <b-col
        cols="12"
        class="d-flex justify-content-end py-1 pb-2"
      >
        <b-button
          class="mx-1"
          variant="primary"
          data-action-type="save"
          @click="
            (val) => {
              triggerAdding();
            }
          "
        >
          {{ $t('save') }}
        </b-button>
        <b-button
          class="mx-1"
          variant="secondary"
          @click="closeModal"
        >
          {{ $t('Close') }}
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>

export default {
  props: {
    storeId: {
      type: [Number, null],
      required: false,
      default: null,
    },
  },
  emits: ['addNewItems'],
  data() {
    return {
      perPage: 25,
      sortDirection: 'asc',
      isSortDirDesc: '',
      currentPage: 1,
      sortBy: '',
      filter: {
        code: '',
        arabicNamePart: '',
        englishNamePart: '',
        storeId: '',
        showSelected: '',
        OrderClause: '',
        itemType: ''
      },
      isTableBusy: false,
      items: [],
      showSelected: false,
      totalRows: 0,
    };
  },
  computed: {
    tableColumns() {
      return [
        {
          key: 'barCode',
          label: this.$t('barcode'),
          sortable: true,
          thStyle: {
            width: ['lg', 'xl'].indexOf(this.currentBreakPoint) > -1 ? '4%' : '10%'
          },
        },
        {
          key: this.isRight ? 'arabicName' : 'englishName',
          label: this.$t('name'),
          sortable: true,
          thStyle: { width: ['lg', 'xl'].indexOf(this.currentBreakPoint) > -1 ? '35%' : '60%' },
        },
        {
          key: 'unit',
          label: this.$t('unit'),
          sortable: true,
          thStyle: { width: ['lg', 'xl'].indexOf(this.currentBreakPoint) > -1 ? '20%' : '70%' },
        },
        {
          key: 'count',
          label: this.$t('quantity'),
          sortable: true,
          thStyle: { width: ['lg', 'xl'].indexOf(this.currentBreakPoint) > -1 ? '5%' : '50%' },
          formatter: (value, key, item) => {
            item.convertedBalance = item.currentBalance / (item.units.find((u) => u.unitId === item.unitId).conversionRate || 1);
            return this.fraction(item.convertedBalance);
          },
        },
        {
          key: 'actions',
          thStyle: { width: ['lg', 'xl'].indexOf(this.currentBreakPoint) > -1 ? '15%' : '50%' },
        },
      ];
    },
  },
  watch: {
    storeId(newVal) {
      this.filter.storeId = newVal;
    },
  },
  mounted() {
    this.filter.storeId = this.storeId;
  },
  methods: {
    adaptQuantity(item, count = 0) {
      const quantity = (item.quantity || 0) + count;
      item.quantity = quantity < 0 ? 0 : quantity;

      const selectedItem = this.items.find((val) => val.id === item.id);

      if (!selectedItem && item.quantity > 0) this.items.push(item);
      else if (item.quantity === 0 && selectedItem) this.items = this.items.filter((val) => val !== selectedItem);
      else if (selectedItem) selectedItem.quantity = item.quantity;
    },
    refreshItems() {
      this.$refs['items-table'].refresh();
    },
    triggerAdding() {
      const addedItems = this.items.map((item) => {
        return {
          ...item,
          itemArabicName: item.arabicName,
          itemEnglishName: item.englishName,
          unitId: item.unitId,
          code: item.code,
          storeId: this.storeId,
          matchedUnitId: item.matchedUnitId,
          itemId: item.id,
          quantity: item.quantity,
          price: item.salesPrice,
          discountPercentage: 0,
          discountValue: 0,
          total: 0,
          net: 0,
          stores: item.stores,
          units: item.units,
        };
      });
      this.$emit('addNewItems', addedItems);
      this.closeModal();
    },
    closeModal() {
      this.items = [];
      this.$refs['search-modal'].hide();
    },
    openModal(type) {
      this.filter.itemType = type;
      if (!this.storeId) {
        this.doneAlert({ text: this.$t('selectStore'), type: 'warning' });
         return
      }
      this.$refs['search-modal'].show();
    },
    itemsSearchProvider(ctx, callback) {
      const {
        currentPage, perPage, sortBy, sortDesc
      } = ctx;

      this.filter.OrderClause = this.orderQuery(sortBy, sortDesc);
      let params = `?pageNumber=${currentPage}&pageSize=${perPage}&`;

      params += this.getFilterObj(this.filter);

      this.isTableBusy = true;
      this.get({ url: `items${params}` })
        .then(({ data, totalCount }) => {
          this.isTableBusy = false;
          this.totalRows = totalCount;
          data.filter((item) => {
            item.unitId = item.mainUnitId;
            item.quantity = 0;
            const store = item.stores.find((val) => val.storeId === this.storeId) || { currentBalance: 0 };
            item.currentBalance = store.currentBalance;
            item.units = [...item.subUnits];
            item.barCode = item.matchedUnitId ? item.units.find((u) => u.unitId === item.matchedUnitId).barcode : item.mainUnitBarcode;
            item.units.push({
              unitId: item.mainUnitId,
              unitArabicName: item.mainUnitArabicName,
              unitEnglishName: item.mainUnitEnglishName,
              salesPrice: item.salesPrice,
              minSalesPrice: item.minSalesPrice,
              maxSalesPrice: item.maxSalesPrice,
              barcode: item.mainUnitBarcode,
              conversionRate: 1,
              isMain: true,
            });
            const existItem = this.items.find((val) => val.id === item.id && val.unitId === item.mainUnitId);
            if (existItem) {
              item.quantity = existItem.quantity;
              item.unitId = existItem.unitId;
            }
            return false;
          });
          callback(data);
        })
        .catch(() => {
          this.isTableBusy = false;
          callback([]);
        });
      return null;
    },
  },
};
</script>

<style>
  /* .quantity-input {
    width: 100px !important;
  } */
  .quantity-input .form-control {
    min-width: 25% !important;
    width: 25% !important;
  }
</style>
