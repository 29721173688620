import {
  paymentWays,
  ItemTransactionPaymentType,
  invoiceValidationTypes
} from '@/libs/acl/Lookups';

export default {
  data() {
    return {
      // lookups entites
      paymentWays: paymentWays,
      itemTransactionTypes: ItemTransactionPaymentType,
      invoiceValidationTypes,
      // lookup object
      stores: [],
      // invoice Model
      selectedItem: {
        code: '',
        transactionDate: '',
        notes: '',
        isPosted: false,
        postedVoucherId: null,
        transactionChannel: 'normal',
        sourceTransactionId: null,
        priceListId: null,
        dealerId: null,
        onTheFlyDealerName: '',
        agentId: null,
        storeId: null,
        driverId: null,
        carId: null,
        projectId: null,
        total: 0,
        discountPercentage: 0,
        discountValue: 0,
        netBeforeTaxes: 0,
        totalTaxes: 0,
        net: 0,
        isComplete: false,
        lines: [],
        taxes: [],
        payments: [],
      },
    };
  },
  computed: {
    tableColumns() {
      return [
        { key: 'paymentDate', label: this.$t('Payment Date'), sortable: false },
        {
          key: 'paymentMethod',
          label: this.$t('paymentType'),
          isLocale: true,
          sortable: false,
        },
        {
          key: this.isRight ? 'arabicName' : 'englishName',
          label: this.$t('safe'),
          sortable: false,
          footer: () => this.$t('remaindered'),
        },
        {
          key: 'value',
          label: this.$t('value'),
          sortable: false,
          footer: () => this.fraction(this.remaindered),
        },
        { key: 'actions' },
      ];
    },
  },
  methods: {
    getModel() {
      const model = JSON.parse(JSON.stringify(this.selectedItem));
      // remove unneeded properities from model items
      model.lines = model.lines.map((item) => {
        return {
          transactionId: item.transactionId || 0,
          lineSerial: item.lineSerial,
          storeId: item.storeId,
          itemId: item.itemId,
          unitId: item.unitId,
          unitConversionRate: item.unitConversionRate,
          quantity: item.quantity,
          notes: item.notes,
        };
      });
      return model;
    },
    updateViewModel() {
      this.get({ url: 'Stores' }).then((data) => {
        this.stores = data;
        if (this.currentBranch.stores && this.currentBranch.stores.length > 0) {
          const branchStores = this.currentBranch.stores.map(
            (item) => item.storeId
          );
          this.stores = this.stores.filter(
            (item) => branchStores.indexOf(item.id) > -1
          );
        }
      });
    },
  },
};
