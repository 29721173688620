var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"my-2"},[_c('div',{attrs:{"align-h":"between"}},[_c('b-row',[_c('b-col',{staticClass:"pb-2",attrs:{"cols":"12"}},[(this.$route.name === 'damage-adjustment-new'
              || this.$route.name === 'damage-adjustment-edit')?_c('b-form-radio-group',{attrs:{"button-variant":"outline-primary","options":_vm.optionsRadio,"buttons":"","size":"sm","name":"radios-btn-default"},model:{value:(_vm.searchType),callback:function ($$v) {_vm.searchType=$$v},expression:"searchType"}}):_vm._e()],1)],1),_c('b-row',{attrs:{"align-h":"between"}},[(_vm.searchType === 'name')?_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-start"},[(this.$route.name === 'damage-adjustment-new'
                || this.$route.name === 'damage-adjustment-edit')?_c('vue-autosuggest',{attrs:{"suggestions":_vm.filteredOptions,"input-props":_vm.inputProps,"get-suggestion-value":_vm.getSuggestionValue,"limit":10},on:{"selected":_vm.onSelected,"click":_vm.clickHandler,"input":_vm.onInputChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var suggestion = ref.suggestion;
return [_c('span',{staticClass:"my-suggestion-item"},[_vm._v(_vm._s(suggestion.item.itemArabicName))])]}}],null,false,2115672636),model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}}):_vm._e()],1)]):_vm._e(),(_vm.searchType === 'barcode')?_c('b-col',{attrs:{"cols":"12","md":"5"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[(this.$route.name === 'damage-adjustment-new'
                || this.$route.name === 'damage-adjustment-edit')?_c('b-form-input',{staticClass:"d-inline-block mr-1",attrs:{"clearable":true,"placeholder":_vm.$t('search')},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.getItemWithBarcode.apply(null, arguments)},"dblclick":function () { return _vm.$refs.searchItemsModal.openModal(_vm.transactionType = 'damageAdjustment'); }},model:{value:(_vm.filter.barcode),callback:function ($$v) {_vm.$set(_vm.filter, "barcode", $$v)},expression:"filter.barcode"}}):_vm._e()],1)]):_vm._e(),_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"md":"2"}},[(this.$route.name === 'damage-adjustment-new'
              || this.$route.name === 'damage-adjustment-edit')?_c('b-button',{attrs:{"variant":"gradient-primary","size":"sm","data-action-type":""},on:{"click":function (v) {_vm.$refs.searchItemsModal.openModal(_vm.transactionType = 'damageAdjustment');}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusSquareIcon"}}),_vm._v(" "+_vm._s(_vm.$t('addItems'))+" ")],1):_vm._e()],1)],1)],1)]),_c('b-table',{ref:"g-table",staticClass:"mb-0",attrs:{"items":_vm.items,"fields":_vm.columns,"primary-key":"barcode","show-empty":"","small":"","sticky-header":"100%","foot-clone":"","sticky-column":"","per-page":_vm.items.length,"current-page":_vm.currentPage,"sort-by":_vm.sortBy,"sort-desc":_vm.isSortDirDesc,"sort-direction":_vm.sortDirection,"empty-text":_vm.$t('noMatchingRecordsFound')},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event}},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({ width: '200px' })})})}},{key:"head(actions)",fn:function(){return [_c('span')]},proxy:true},{key:"cell(mainUnitId)",fn:function(ref){
              var item = ref.item;
return [_c('g-field',{staticClass:"mb-0",attrs:{"field":"select","item-id":"unitId","placeholder":"unit","disabled":"","value":item.unitId,"options":item.units,"label":_vm.isRight ? 'unitArabicName' : 'unitEnglishName',"dir":_vm.isRight ? 'rtl' : 'ltr',"readonly":""},on:{"update:value":function($event){return _vm.$set(item, "unitId", $event)}}})]}},{key:"cell(arabicName)",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticStyle:{"min-width":"150px"}},[_vm._v(" "+_vm._s(item.arabicName))])]}},{key:"cell(quantity)",fn:function(ref){
              var item = ref.item;
return [_c('g-field',{staticClass:"mb-0",style:({ width: '200px' }),attrs:{"value":item.quantity,"type":"number","rules":"required|min_value:0"},on:{"update:value":function($event){return _vm.$set(item, "quantity", $event)},"change":function () {
          _vm.getSummary();
          _vm.$emit('validate-balance');
        }}})]}},(this.$route.name === 'damage-adjustment-new'
        || this.$route.name === 'damage-adjustment-edit')?{key:"cell(actions)",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"text-nowrap"},[_c('feather-icon',{staticClass:"mx-1 clickable danger",attrs:{"id":("invoice-row-" + (item.id) + "-delete-icon"),"icon":"TrashIcon","stroke":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}}),_c('b-tooltip',{attrs:{"title":_vm.$t('delete'),"placement":"bottom","target":("invoice-row-" + (item.id) + "-delete-icon")}})],1)]}}:null,{key:"foot()",fn:function(){return [_c('span')]},proxy:true},{key:"foot(quantity)",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.fraction(_vm.totalQuantity))+" ")])]},proxy:true}],null,true)}),_c('search-items',{ref:"searchItemsModal",attrs:{"store-id":_vm.storeId},on:{"addNewItems":_vm.addItems}}),_c('p',{staticClass:"d-none"},[_vm._v(" "+_vm._s(_vm.isDefaultStore)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }