<template>
  <b-card>
    <g-form @submit="save">
      <b-row class="my-2">
        <b-col md="12">
          <div class="row">
            <b-col md="3">
              <!-- code  -->
              <g-field
                id="code"
                type="number"
                :value.sync="selectedItem.code"
                label-text="code"
                filed="input"
                name="code"
                readonly
              />
            </b-col>
            <b-col
              v-if="currentBranch.enableEditingTransactionDates"
              md="3"
            >
              <g-picker
                :value.sync="selectedItem.transactionDate"
                label-text="transactionDate"
                name="date"
              />
            </b-col>
            <b-col
              v-if="!currentBranch.enableEditingTransactionDates"
              md="3"
            >
              <g-field
                :value="getDate(selectedItem.transactionDate)"
                label-text="transactionDate"
                disabled
                name="date"
              />
            </b-col>
            <b-col md="3">
              <g-field
                :value.sync="selectedItem.transactionTime"
                label-text="transactionTime"
                filed="input"
                name="transactionTime"
                readonly
              />
            </b-col>
            <b-col md="3">
              <g-field
                :options="stores"
                label-text="store"
                field="select"
                rules="required"
                name="StoreId"
                :value.sync="selectedItem.storeId"
                :dir="isRight ? 'rtl' : 'ltr'"
                :label="isRight ? 'arabicName' : 'englishName'"
                :disabled="(selectedItem.lines && selectedItem.lines.length > 0)"
              />
            </b-col>
            <b-col
              v-if="id"
              md="3"
            >
              <g-field
                label-text="createdBy"
                :value="selectedItem.createdUserName"
                placeholder="createdBy"
                name="createdBy"
                readonly
              />
            </b-col>
            <b-col
              v-if="id && selectedItem.updatedUserName"
              md="3"
            >
              <g-field
                label-text="updatedBy"
                :value="selectedItem.updatedUserName"
                placeholder="updatedBy"
                name="updatedBy"
                readonly
              />
            </b-col>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="12">
          <items
            :store-id="selectedItem.storeId"
            :items.sync="selectedItem.lines"
            @validate-balance="() => validateBalance()"
          />
        </b-col>
      </b-row>
      <b-row class="pt-1">
        <!-- notes -->
        <b-col md="12">
          <b-form-group :label="$t('notes')">
            <b-form-textarea
              id="textarea"
              v-model="selectedItem.notes"
              label-text="Notes"
              rows="3"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="!selectedItem.id ||this.$route.name === 'damage-adjustment-new'
          || this.$route.name === 'damage-adjustment-edit'"
      >
        <b-col
          cols="12"
          class="d-flex justify-content-end sticky-bottom"
        >
          <b-button
            v-permission="$route.meta.permission"
            class="ml-1"
            variant="relief-primary"
            data-action-type="save"
            :disabled="!selectedItem.lines.length"
            @click="save('save')"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            {{ $t('save') }}
          </b-button>
          <b-button
            v-permission="$route.meta.permission"
            class="ml-1"
            variant="outline-primary"
            data-action-type="saveAndPrint"
            :disabled="!selectedItem.lines.length"
            @click="save('saveAndPrint')"
          >
            <feather-icon
              icon="PrinterIcon"
              class="mr-50"
            />
            {{ $t('saveAndPrint') }}
          </b-button>
          <b-button
            v-if="id || currentBranch.isMaster"
            class="ml-1"
            variant="outline-primary"
            :disabled="selectedItem.lines.length <= 0"
            @click="print(id)"
          >
            <feather-icon
              icon="PrinterIcon"
              class="mr-50"
            />
            {{ $t('print') }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>

<script>
import storeMixin from '@/mixin/storeMixin';
import reportMixin from '@/mixin/reportMixin';
import items from './components/items.vue';

export default {
  components: {
    items,
  },
  mixins: [storeMixin, reportMixin],
  props: ['id'],
  data() {
    return {
      items: [],
      errors: [],
    };
  },
  watch: {
    'selectedItem.lines'(newVal, oldVal) {
      if (oldVal && oldVal.length > 0) {
        this.errors = [];
      }
    },
  },
  mounted() {
    this.updateViewModel();
    if (this.id) {
      if (this.$route.name === 'shortage-adjustment-edit') {
        this.urlName = 'ItemTransactions/ShortageAdjustment';
      } else if (this.$route.name === 'overflow-adjustment-edit') {
        this.urlName = 'ItemTransactions/OverflowAdjustment';
      } else {
        this.urlName = 'ItemTransactions/DamageAdjustment';
      }
      this.get({ url: `${this.urlName}/${this.id}` }).then((data) => {
        this.selectedItem = data;
        this.selectedItem.transactionTime = this.getTime(this.selectedItem.transactionTime)
        data.lines.forEach((item) => {
          item.transactionId = this.selectedItem.id;
          item.units = [
            {
              unitId: item.unitId,
              unitArabicName: item.unitArabicName,
              unitEnglishName: item.unitEnglishName,
              price: item.salesPrice,
              minSalesPrice: item.minSalesPrice,
              maxSalesPrice: item.maxSalesPrice,
              barcode: item.mainUnitBarcode,
              isMain: true,
            },
          ];
        });
      });
    } else {
      this.selectedItem.transactionDate = this.today;
      this.selectedItem.transactionTime = this.getTime(this.time);
      this.selectedItem.storeId = this.currentBranch.defaultStoreId || null;
    }
  },
  methods: {
    validateBalance() {
      if (this.$route.name === 'shortage-adjustment-new' || this.$route.name === 'shortage-adjustment-edit') {
        this.selectedItem.transactionType = 'shortageAdjustment';
      } else if (this.$route.name === 'overflow-adjustment-new' || this.$route.name === 'overflow-adjustment-edit') {
        this.selectedItem.transactionType = 'overflowAdjustment';
      } else {
        this.selectedItem.transactionType = 'damageAdjustment';
      }
      this.create({
        url: 'ItemTransactions/validate/item-balance',
        data: this.selectedItem,
      })
        .then(() => {})
        .catch(({ data }) => {
          this.errors.push({
            text: data.detail,
            type: 'error',
            errorType: 'balance',
          });
        });
    },
    save(type) {
      this.selectedItem.netBeforeTaxes = this.selectedItem.lines.reduce((sum, item) => {
        sum += item.net;
        return sum;
      }, 0);
      this.selectedItem.net = this.selectedItem.netBeforeTaxes;
      this.selectedItem.total = this.selectedItem.netBeforeTaxes;
      if (this.$route.name === 'shortage-adjustment-new' || this.$route.name === 'shortage-adjustment-edit') {
        this.selectedItem.transactionType = 'shortageAdjustment';
        this.createUrl = 'ItemTransactions/ShortageAdjustment';
      } else if (this.$route.name === 'overflow-adjustment-new' || this.$route.name === 'overflow-adjustment-edit') {
        this.selectedItem.transactionType = 'overflowAdjustment';
        this.createUrl = 'ItemTransactions/OverflowAdjustment';
      } else {
        this.selectedItem.transactionType = 'damageAdjustment';
        this.createUrl = 'ItemTransactions/DamageAdjustment';
        if (this.selectedItem.lines.find(a => a.quantity <= 0)) {
        this.doneAlert({
          text: this.$t('youAreNotAllowedToAddQtyEqualZero'),
          type: 'error'
          });
        return;
      }
      if (this.errors.length > 0) {
        this.doneAlert(this.errors[0]);
        return;
      }
      }
      // if (this.errors.length > 0) {
      //   this.doneAlert(this.errors[0]);
      //   return;
      // }
          if (this.selectedItem.id) {
        this.update({
          url: this.createUrl,
          data: this.getModel(),
          id: this.selectedItem.id,
        })
          .then(() => {
            this.doneAlert({ text: this.$t('updatedSuccessfully') });
            this.$router.push({ name: 'damage-adjustment' });
            const id = this.id;
            if (type === 'saveAndPrint') this.print(id);
            });
          } else {
        this.create({
          url: this.createUrl,
          data: this.getModel(),
        })
          .then((dataId) => {
            this.doneAlert({
              title: this.$t('savedSuccessfully'),
              type: 'success',
            });
            const id = dataId || this.id;
            if (type === 'saveAndPrint') this.print(id);
            if (this.$route.name === 'shortage-adjustment-new') {
              this.$router.push({ name: 'shortage-adjustment' });
            } else if (this.$route.name === 'overflow-adjustment-new') {
              this.$router.push({ name: 'overflow-adjustment' });
            } else {
              this.$router.push({ name: 'damage-adjustment' });
            }
          });
    }
    },
      print(id) {
      const printedItem = {
        id: id,
        transactionType: 12
      }
       this.printReport('DamageAdjustment-ar', printedItem);
    },
  },
};
</script>
